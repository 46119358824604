<template>
  <!-- 健康宣教 -->
  <div class="health-education">
    <!-- 搜索 -->
    <van-search
      v-model="value"
      placeholder="请输入搜索关键词"
      @search="onSearch"
      @clear="clear"
      @input="input"
      shape="round"
    />
    <!-- 内容 -->
    <div class="healthy-content">
      <Scroll class="scroll">
        <div
          v-for="(item,index) in show ? text : list"
          :key="index"
        >
          <div
            class="content-text"
            v-html="item"
            @click="healthy(index)"
          />
        </div>
      </Scroll>
    </div>
  </div>
</template>

<script>
import Scroll from '@/components/content/scroll/BScroll'
export default {
  name: "healthEducation",
  data () {
    return {
      value: '',
      list: [`<div style="overflow:hidden">
            <h4>梳头养生 健康又防病</h4>
            <span class="a">梳头是每个人每天必须要做的事情。梳头发不仅仅是整理发型，更不是例行公事的一种方式，殊不知正确的梳头方法，还能起到养生防病的效果。因此千万不能小看梳头发这个简单的小动作，养生从头开始。下面我们一起来看看梳头养生防病的方法，以及梳头时的注意事项。</span>
            <span
              style="float:right"
              class="small-size"
            >时间:2021-02-20</span>
          </div>`, `<div style="overflow:hidden">
            <h4>健康养生常识 教你学会指甲的养生之道</h4>
            <span class="a">你知道吗？随着现在生活越来科学化的发展，我们可以根据指甲的一些情况来判断身体健康状况，一
              旦朋友们的身体中出现一些疾病，很多情况下是可以通过指甲来进行一个反应，建议朋友们朋友们通
              过观察指甲来了解身体的状况，从而达到养生的目的哦。</span>
            <span
              style="float:right"
              class="small-size"
            >时间:2021-02-23</span>
          </div>`, `<div style="overflow:hidden">
            <h4>“二八”健康法则 防病又养生</h4>
            <span class="a">目录：
              第一章：“二八”健康法则 防病又养生
              第二章：常洗冷水澡 防病又养生
              第三章：养生防病 不妨学学“走路操”
            </span>
            <span
              style="float:right"
              class="small-size"
            >时间:2021-02-04</span>
          </div>`, `<div style="overflow:hidden">
            <h4>冬季健康养生常识</h4>
            <span class="a">人到冬季身体新陈代谢变慢，抵抗力下降，容易生病，而且特别容易损阳气。冬季养生并不是想象那
              么复杂，平时留意一些保健的小常识，也可以达到养生的功效。下面小编介绍9个冬季养生的健康小
              常识，教你少生病的养生之道。</span>
            <span
              style="float:right"
              class="small-size"
            >时间:2021-03-01</span>
          </div>`],
      text: [],
      show: false
    }
  },
  components: {
    Scroll
  },
  mounted () {
    let list = document.getElementsByTagName('h4')
    let data = document.getElementsByClassName('a')
    for (let i = 0; i < list.length; i++) {
      list[i].setAttribute('style', `line-height: 1.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;`)
    }
    for (let i = 0; i < data.length; i++) {
      data[i].setAttribute('style', `line-height: 1.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;`)
    }
  },
  methods: {
    onSearch () {
      this.text = []
      let list = document.getElementsByTagName('h4')
      for (let i = 0, len = list.length; i < len; i++) {
        if (list[i].innerHTML.includes(this.value)) {
          this.show = true
          this.text.push(this.list[i])
        } else {
          this.show = true
        }
      }
    },
    healthy (i) {
      this.$router.push('/healthy/' + i)
    },
    input () {
      if (this.value.length == 0) {
        this.show = false
        this.$nextTick(() => {
          let list = document.getElementsByTagName('h4')
          let data = document.getElementsByClassName('a')
          for (let i = 0; i < list.length; i++) {
            list[i].setAttribute('style', `line-height: 1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;`)
          }
          for (let i = 0; i < data.length; i++) {
            data[i].setAttribute('style', `line-height: 1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;`)
          }
        })
      }
    },
    //清除数据时触发
    clear () {
      this.text = []
      this.show = false
      this.$nextTick(() => {
        let list = document.getElementsByTagName('h4')
        let data = document.getElementsByClassName('a')
        for (let i = 0; i < list.length; i++) {
          list[i].setAttribute('style', `line-height: 1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;`)
        }
        for (let i = 0; i < data.length; i++) {
          data[i].setAttribute('style', `line-height: 1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;`)
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.health-education {
  .healthy-content {
    .scroll {
      width: 95vw;
      margin: auto;
      height: calc(100vh - 3.375rem);
      overflow: hidden;
      .content-text {
        background-color: #f7f8fa;
        padding: 0.5rem;
        border-radius: 0.3rem;
        margin-bottom: 1rem;
        box-sizing: border-box;
        h4,
        span {
          line-height: 1.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }
    }
  }
}
</style>